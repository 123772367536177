<template>
  <div class="">
    <el-card shadow="never">
      <div class="d-flex">
        <div>
          <el-input
            class="w220 mr10"
            v-model="searchTitle"
            placeholder="请输入标题查询"
          />
          <el-date-picker
            v-model="pickerValue"
            class="mr10"
            align="right"
            value-format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          />
          <el-button type="primary" @click="fnQueryClick">查询</el-button>
        </div>
        <div style="margin-left: auto">
          <el-button type="primary" @click="$router.push('electronBookEdit')"
            >添加图册</el-button
          >
        </div>
      </div>

      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column prop="aid" align="center" label="编号" />
          <el-table-column prop="title" align="center" label="标题" />
          <el-table-column prop="sortName" align="center" label="标题" />
          <el-table-column prop="count" align="center" label="封面">
            <div slot-scope="scope">
              <img
                class="w50 h50"
                :src="scope.row.cover + '.icon.png'"
                alt=""
              />
            </div>
          </el-table-column>
          <el-table-column prop="count" align="center" label="图册">
            <div class="flex flex-ard" slot-scope="scope">
              <div v-for="(i, n) in scope.row.photoAlbum" :key="n">
                <img
                  v-if="n < 3"
                  class="w50 h50"
                  :src="i.url + '.icon.png'"
                  alt=""
                />
              </div>
            </div>
          </el-table-column>
          <el-table-column prop="count" align="center" label="图册数量">
            <div class="flex flex-ard" slot-scope="scope">
              {{ scope.row.photoAlbum.length || 0 }}
            </div>
          </el-table-column>
          <el-table-column prop="UpdateTime" align="center" label="更新时间" />
          <!-- <el-table-column prop="userName" align="center" label="排序" /> -->
          <el-table-column align="center" label="操作">
            <div slot-scope="scope">
              <el-button type="text" @click="openLog(scope.row, 1)"
                >设置</el-button
              >
              <el-popconfirm
                class="ml-12"
                title="是否删除图册？"
                @confirm="openLog(scope.row, 2)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </div>
          </el-table-column>
        </template>
      </commonTable>
    </el-card>
    <el-dialog
      title="添加图册"
      :visible.sync="dialogVisible"
      width="50%"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="78px"
      >
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="ruleForm.title"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="封面" prop="coverImg">
          <el-upload
            :class="{
              'avatar-uploader': true,
              'avatar-coverImg': true,
              disabled: uploadDisabled,
            }"
            ref="uploadIcon"
            :action="$store.state.uploadingUrl"
            list-type="picture-card"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessCoverImg"
            :on-remove="handleRemove"
            :file-list="fileList"
            :limit="1"
            accept=".png,.jpg"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              *格式png、jpg，大小不超过2MB。
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="图册" prop="coverImg">
          <el-upload
            :class="{
              'avatar-uploader': true,
              'avatar-coverImg': true,
            }"
            ref="uploadIcon"
            :action="$store.state.uploadingUrl"
            list-type="picture-card"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessBook"
            :on-remove="handleRemoveBook"
            :file-list="fileListBook"
            accept=".png,.jpg"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              *格式png、jpg，大小不超过2MB。
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
<script>
import commonTable from "@/components/common/commonTable";
import {
  albumAddAlbum,
  albumUpdateAlbum,
  albumDeleteAlbum,
  albumSelectAlbum,
} from "@/api/electronBook";
export default {
  components: {
    commonTable,
  },
  data() {
    return {
      uploadData: {
        icon: true,
        preview: true,
      },
      dialogVisible: false,
      ruleForm: {},
      rules: {},
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 1, //总条数
      isEdit: false,
      fileList: [],
      fileListBook: [],
      searchTitle: "",
      pickerValue: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    fnQueryClick() {
      console.log(this.searchTitle, "88888");
      console.log(this.pickerValue, "999999");
      this.currentPage = 1;
      this.pageSize = 10;
      this.getData();
    },
    addBook() {
      this.dialogVisible = true;
      this.ruleForm = {};
      this.isEdit = false;
      this.fileList = [];
      this.fileListBook = [];
    },
    submit() {
      this.ruleForm.cid = localStorage.getItem("companyId");
      if (!this.ruleForm.title) {
        this.$message.warning("请输入标题！");
        return;
      }
      if (!this.ruleForm.cover) {
        this.$message.warning("请上传封面！");
        return;
      }
      if (this.fileListBook.length < 1) {
        this.$message.warning("请最少上传一个图册！");
        return;
      }
      let arr = JSON.parse(JSON.stringify(this.fileListBook));
      let arr1 = [];
      arr.forEach((el) => {
        // this.ruleForm.photoAlbum
        arr1.push(el.url);
      });
      this.ruleForm.photoAlbum = arr1.join(",");
      if (!this.isEdit) {
        console.log(this.ruleForm, "添加数据");
        albumAddAlbum(this.ruleForm).then((res) => {
          if (res.code == 200) {
            this.$message.success("保存成功！");
            this.dialogVisible = false;
            this.getData();
          }
        });
      } else {
        albumUpdateAlbum(this.ruleForm).then((res) => {
          if (res.code == 200) {
            this.$message.success("保存成功！");
            this.dialogVisible = false;
            this.getData();
          }
        });
      }
    },
    getData() {
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        cid: localStorage.getItem("companyId"),
        title: this.searchTitle,
        startTime: this.pickerValue[0] || "",
        endTime: this.pickerValue[1] || "",
      };
      console.log(data, "请求数据----");
      albumSelectAlbum(data).then((res) => {
        this.tableData = res.data.list;
        if (this.tableData.length > 0) {
          this.tableData.forEach((el) => {
            el.photoAlbum = JSON.parse(el.photoAlbum);
          });
          console.log(this.tableData, "列表数据");
        }
        console.log(this.tableData, "列表数据-----");
        this.total = res.data.total;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    openLog(item, i) {
      this.fileList = [];
      this.fileListBook = [];
      if (i == 1) {
        let items = JSON.stringify(item);
        console.log(item, "页面传递的数据");
        this.$router.push({
          name: "electronBookEdit",
          params: { item: item },
        });
        // let arr = JSON.parse(JSON.stringify(item));
        // this.fileList = [{ name: 'img', url: arr.cover }];
        // arr.photoAlbum.forEach(el => {
        //     this.fileListBook.push({ name: 'img', url: el })
        // })
        // this.ruleForm = arr;
        // console.log(this.ruleForm, '打开的数据');
        // console.log(this.fileListBook, '打开的数据');
        // this.isEdit = true;
        // this.dialogVisible = true;
      } else if (i == 2) {
        albumDeleteAlbum({ aid: item.aid }).then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功！");
            this.getData();
          }
        });
      }
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if (this.isUpdate) {
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.cover = "";
      this.fileList = [];
    },
    // 封面
    handleAvatarSuccessCoverImg(res, file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList, "fileList");
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.cover = `https://cdn-oss.jquen.com/${res.data}`;
    },
    handleRemoveBook(file, fileList) {
      console.log(file, fileList);
      this.fileListBook = fileList;
    },
    // 封面
    handleAvatarSuccessBook(res, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.fileListBook.push({
        name: "img",
        url: `https://cdn-oss.jquen.com/${res.data}`,
      });
      // this.ruleForm.icon = `https://cdn-oss.jquen.com/${res.data}`;
    },
  },
};
</script>
    
<style lang="scss" scoped>
::v-deep .disabled .el-upload--picture-card {
  display: none;
}
</style>